import React, { useState, useEffect } from 'react'

import FAQSection from '../components/FAQSection'
import IntermediateCTA from '../components/IntermediateCTA'
import MainHero from '../components/MainHero'
import ProcessSection from '../components/ProcessSection'
import FinalCTA from '../components/FinalCTA'
import Testimonials from '../components/Testimonials'
import Layout from '../components/Layout'

const IndexPage = () => {
  return (
    <Layout>
      <MainHero />
      <Testimonials transitionSpeed={0.2} animationSpeed={3} />
      <IntermediateCTA />
      <ProcessSection transitionSpeed={0.2} animationSpeed={3} />
      <FAQSection />
      <FinalCTA />
    </Layout>
  )
}

export default IndexPage
