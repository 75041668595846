import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import CtaButton from '../components/CtaButton.js'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const FinalCTA = () => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiFinalCtaSection {
        edges {
          node {
            title
            locale
            text
            ctaButtonText
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `)

  const dataLocalized = data.allStrapiFinalCtaSection.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { title, text, ctaButtonText, photo } = dataLocalized.node

  return (
    <article className='final-cta'>
      <div className='wrapper'>
        <div className='final-cta-illustration'>
          <div className='wrapper'>
            <GatsbyImage
              image={getImage(photo.localFile)}
              alt={''}
              className='image'
            />
            <div className='illustration'></div>
          </div>
        </div>
        <div className='inner-wrapper'>
          <div className='section-title -left'>
            <h2 className='title'>
              <div className='wrapper'>{title}</div>
            </h2>
          </div>
          <div className='text'>
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
          <CtaButton className='button -lg -light' label={ctaButtonText} />
        </div>
      </div>
    </article>
  )
}

export default FinalCTA
