import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { LogoWhatsapp, Mail } from 'react-ionicons'
import { useStaticQuery, graphql } from 'gatsby'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

import FAQQuestion from './FAQQuestion.js'

const FAQSection = () => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiFaqSection {
        edges {
          node {
            id
            locale
            title
            subtitle
            furtherHelpTitle
            furtherHelpText
          }
        }
      }
      strapiContactInfo {
        phonenumber
        emailaddress
      }
      allStrapiFaqs {
        edges {
          node {
            id
            locale
            question
            answer
          }
        }
      }
    }
  `)

  const edges = data.allStrapiFaqs.edges.filter(
    item => item.node.locale === state.language
  )

  const dataLocalized = data.allStrapiFaqSection.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const {
    id,
    title,
    subtitle,
    furtherHelpText,
    furtherHelpTitle
  } = dataLocalized.node

  const { phonenumber, emailaddress } = data.strapiContactInfo

  return (
    <article className='faq-section' id={id}>
      <div className='wrapper'>
        <div className='illustration'></div>
        <div className='section-title'>
          <div className='secondary-title'>{subtitle}</div>
          <h2 className='title'>
            <div className='wrapper'>{title}</div>
          </h2>
        </div>
        <div className='faq-questions'>
          {edges.map(item => (
            <FAQQuestion item={item} key={item.node.id} />
          ))}
        </div>
        <section className='further-help'>
          <h3 className='title'>{furtherHelpTitle}</h3>
          <p>{furtherHelpText}</p>
          <ul className='contact-list'>
            <li className='contact-item'>
              <LogoWhatsapp />
              {phonenumber}
            </li>
            <li className='contact-item'>
              <Mail />
              {emailaddress}
            </li>
          </ul>
        </section>
      </div>
    </article>
  )
}

export default FAQSection
