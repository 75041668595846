import React, { useState, useEffect, useRef } from 'react'
import { AddOutline } from 'react-ionicons'
import useBreakPoint from './useBreakPoint'
import ReactMarkdown from 'react-markdown'

const FAQQuestion = props => {
  const FAQQuestionRef = useRef()
  const { id, question, answer } = props.item.node

  const [FAQQuestionHeight, setFAQQuestionHeight] = useState(null)
  const [textHeight, setTextHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [breakPoint, setBreakPoint] = useBreakPoint()

  useEffect(() => {
    calculateHeights()
    window.addEventListener('resize', calculateHeights)
    return () => window.removeEventListener('resize', calculateHeights)
  }, [])

  const calculateHeights = () => {
    const FAQQuestionPadding = parseFloat(
      window.getComputedStyle(FAQQuestionRef.current).paddingTop
    )

    const titleMargin = parseFloat(
      window.getComputedStyle(FAQQuestionRef.current.querySelector('.title'))
        .marginBottom
    )

    setFAQQuestionHeight(
      FAQQuestionRef.current.querySelector('.title').getBoundingClientRect()
        .height +
        FAQQuestionPadding * 2
    )

    setTextHeight(
      FAQQuestionRef.current.querySelector('.text').getBoundingClientRect()
        .height +
        FAQQuestionPadding +
        titleMargin
    )
  }

  return (
    <section
      className='faq-question'
      key={id}
      ref={FAQQuestionRef}
      style={{
        height: `${
          isOpen ? FAQQuestionHeight + textHeight : FAQQuestionHeight
        }px`
      }}
    >
      <h3 className='title'>
        <button className='title-button' onClick={() => setIsOpen(!isOpen)}>
          {question}
        </button>
      </h3>
      <button
        className='expand-button'
        onClick={() => setIsOpen(!isOpen)}
        style={{ transform: `rotate(${isOpen ? '45' : '0'}deg)` }}
      >
        <AddOutline />
      </button>
      <div className='text' style={{ opacity: isOpen ? 1 : 0 }}>
        <ReactMarkdown>{answer}</ReactMarkdown>
      </div>
    </section>
  )
}

export default FAQQuestion
