import React, { useState } from 'react'
import { ArrowBackOutline, ArrowForwardOutline } from 'react-ionicons'

const ProgressIndicator = props => {
  const renderButtons = () => {
    const buttons = []
    for (let i = 0; i < props.count; i++) {
      buttons.push(
        <button
          className={`progress-dot${i === props.index ? ' -active' : ''}`}
          key={'button_' + i}
          onClick={() => props.onButtonClick(i)}
        ></button>
      )
    }
    return buttons
  }

  return (
    <div className='progress-indicator'>
      <div className='wrapper'>
        {props.arrowsEnabled && (
          <div className='progress-arrow' onClick={props.onArrowLeftClick}>
            <ArrowBackOutline color='#7284A1' width='48px' height='48px' />
          </div>
        )}
        {renderButtons()}
        {props.arrowsEnabled && (
          <div className='progress-arrow' onClick={props.onArrowRightClick}>
            <ArrowForwardOutline color='#7284A1' width='48px' height='48px' />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgressIndicator
