import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { useStaticQuery, graphql } from 'gatsby'
import { useInViewport } from 'react-in-viewport'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useSwipeable } from 'react-swipeable'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

import ProgressIndicator from './ProgressIndicator'
import useSlider from './useSlider'

const Testimonials = props => {
  const [state, actions] = useLanguage()
  const testimonialsRef = React.createRef()

  const data = useStaticQuery(graphql`
    query {
      allStrapiTestimonialsSection {
        edges {
          node {
            id
            locale
            title
            subtitle
          }
        }
      }
      allStrapiTestimonials {
        edges {
          node {
            id
            locale
            name
            text
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 200, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `)

  const edges = data.allStrapiTestimonials.edges.filter(
    item => item.node.locale === state.language
  )

  const dataLocalized = data.allStrapiTestimonialsSection.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { id, title, subtitle } = dataLocalized.node

  const {
    slideIndex: testimonialIndex,
    setSlideIndex: setTestimonialIndex,
    swipeHandlers,
    slideTimeout: testimonialTimout,
    slidesTransform: testimonialsTransform
  } = useSlider({
    animationIntervallEnabled: false,
    animationSpeed: props.animationSpeed,
    enableAtBreakPoint: 'sm',
    max: edges.length,
    slideClassName: 'testimonial',
    slidesRef: testimonialsRef
  })

  const renderTestimonials = () =>
    edges.map(item => (
      <figure className='testimonial' key={item.node.id}>
        <div className='photo'>
          <GatsbyImage
            image={getImage(item.node.photo.localFile)}
            alt={item.node.name}
            className='image'
          />
        </div>
        <blockquote className='quote'>
          <p>{item.node.text}</p>
        </blockquote>
        <figcaption className='name'>{item.node.name}</figcaption>
      </figure>
    ))

  const handleProgressIndicatorButtonClick = index => {
    clearInterval(testimonialTimout)
    console.log(index, 'index')
    setTestimonialIndex(index)
  }

  return (
    <article className='testimonials-section' id={id}>
      <div className='wrapper'>
        <div className='testimonial-illustration'>
          <img src='/testimonial-illustration.svg' alt='' />
        </div>
        <div className='section-title'>
          <div className='secondary-title'>{subtitle}</div>
          <h2 className='title'>
            <span className='wrapper'>{title}</span>
          </h2>
        </div>
        <div className='testimonials' {...swipeHandlers}>
          <div className='mask'>
            <div
              className='testimonials-list'
              ref={testimonialsRef}
              style={{
                transform: `translate(${testimonialsTransform}px)`,
                transition: `transform ${props.transitionSpeed}s ease-in-out`
              }}
            >
              {renderTestimonials()}
            </div>
          </div>
          <ProgressIndicator
            count={edges.length}
            index={testimonialIndex}
            onButtonClick={handleProgressIndicatorButtonClick}
          />
        </div>
      </div>
    </article>
  )
}

export default Testimonials
