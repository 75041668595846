import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { useStaticQuery, graphql } from 'gatsby'

import CtaButton from '../components/CtaButton.js'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const IntermediateCTA = () => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiIntermediateCtaSection {
        edges {
          node {
            title
            locale
            text
            ctaButtonText
          }
        }
      }
    }
  `)

  const dataLocalized = data.allStrapiIntermediateCtaSection.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { title, text, ctaButtonText } = dataLocalized.node

  return (
    <div className='intermediate-cta'>
      <div className='outer-wrapper'>
        <div className='intermediate-cta-illustration'>
          <img src='/intermediate-cta-illustration.svg' alt='' />
        </div>
        <div className='inner-wrapper'>
          <h2 className='title'>{title}</h2>
          <p className='text'>{text}</p>
          <CtaButton className='button -lg -pink' label={ctaButtonText} />
        </div>
      </div>
    </div>
  )
}

export default IntermediateCTA
