import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

import ProgressIndicator from './ProgressIndicator'
import useSlider from './useSlider'

const ProcessSection = props => {
  const [state, actions] = useLanguage()
  const processStepsRef = React.createRef()

  const data = useStaticQuery(graphql`
    query {
      allStrapiProcessSection {
        edges {
          node {
            id
            locale
            title
            subtitle
          }
        }
      }
      allStrapiProcessSteps {
        edges {
          node {
            id
            locale
            text
            title
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 512, height: 300, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `)

  const edges = data.allStrapiProcessSteps.edges.filter(
    item => item.node.locale === state.language
  )

  const dataLocalized = data.allStrapiProcessSection.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { id, title, subtitle } = dataLocalized.node

  const {
    slideIndex: processSectionIndex,
    setSlideIndex: setProcessSectionIndex,
    swipeHandlers,
    slideTimeout: processSectionTimout,
    slidesTransform: processSectionTransform,
    updateIndex: updateProcessSectionIndex
  } = useSlider({
    animationSpeed: props.animationSpeed,
    max: edges.length,
    slidesRef: processStepsRef,
    slideClassName: 'process-step',
    animationIntervallEnabled: false
  })

  const handleProgressIndicatorButtonClick = index => {
    clearInterval(processSectionTimout)
    setProcessSectionIndex(index)
  }

  const renderProcessSteps = () =>
    edges.map((item, index) => (
      <li
        className={`process-step${
          index === processSectionIndex ? ' -active' : ''
        }`}
        key={item.node.id}
      >
        <div className='photo'>
          <GatsbyImage
            image={getImage(item.node.photo.localFile)}
            alt={item.node.name}
            className='image'
          />
        </div>
        <div className='wrapper'>
          <div className='number-wrapper'>
            <div className='number'>
              <span className='wrapper'>{index + 1 + '.'}</span>
            </div>
          </div>
          <div className='text'>
            <h3>{item.node.title}</h3>
            <p>{item.node.text}</p>
          </div>
        </div>
      </li>
    ))

  return (
    <article className='process-section' id={id}>
      <div className='wrapper'>
        <div className='process-illustration'>
          <img
            src='/process-section-illustration.svg'
            alt=''
            className='image'
          />
        </div>
        <div className='section-title'>
          <div className='secondary-title'>{subtitle}</div>
          <h2 className='title'>
            <div className='wrapper'>{title}</div>
          </h2>
        </div>
        <div className='process-steps' {...swipeHandlers}>
          <div className='mask'>
            <ol
              className='steps-list'
              ref={processStepsRef}
              style={{
                transform: `translate(${processSectionTransform}px)`,
                transition: `transform ${props.transitionSpeed}s ease-in-out`
              }}
            >
              {renderProcessSteps()}
            </ol>
          </div>
          <ProgressIndicator
            count={edges.length}
            index={processSectionIndex}
            onButtonClick={handleProgressIndicatorButtonClick}
            arrowsEnabled={true}
            onArrowLeftClick={() => updateProcessSectionIndex(false)}
            onArrowRightClick={() => updateProcessSectionIndex()}
          />
        </div>
      </div>
    </article>
  )
}

export default ProcessSection
