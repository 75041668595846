import React, { useState, useEffect } from 'react'
import { createHook } from 'react-sweet-state'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

import ProgressIndicator from './ProgressIndicator'
import useSlider from './useSlider'

const HeroKSP = props => {
  const [state, actions] = useLanguage()
  const slidesRef = React.createRef()

  const data = useStaticQuery(graphql`
    query {
      allStrapiKeySellingPoints {
        edges {
          node {
            text
            title
            locale
            illustration {
              id
              created_at
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)

  const edges = data.allStrapiKeySellingPoints.edges.filter(
    item => item.node.locale === state.language
  )

  const {
    slideIndex,
    setSlideIndex,
    swipeHandlers,
    slideTimout,
    slidesTransform
  } = useSlider({
    animationSpeed: props.animationSpeed,
    max: edges.length,
    slidesRef
  })

  const renderSlides = () =>
    edges.map(item => (
      <div className='slide' key={item.node.id}>
        <h3 className='ksp-title'>
          <span className='rounded-underline -sm'>{item.node.title}</span>
        </h3>
        <p className='ksp-text'>{item.node.text}</p>
        <div className='ksp-illustration'>
          <img
            src={item.node.illustration.localFile.url}
            alt={item.node.title}
            className='image'
          />
        </div>
      </div>
    ))

  const handleProgressIndicatorButtonClick = index => {
    clearInterval(slideTimout)
    setSlideIndex(index)
  }

  return (
    <div className='hero-ksp -soft-edges-disabled' {...swipeHandlers}>
      <div className='wrapper'>
        <div
          className='slides'
          ref={slidesRef}
          style={{
            transform: `translate(${slidesTransform}px)`,
            transition: `transform ${props.transitionSpeed}s ease-in-out`
          }}
        >
          {renderSlides()}
        </div>
      </div>
      <ProgressIndicator
        count={edges.length}
        index={slideIndex}
        onButtonClick={handleProgressIndicatorButtonClick}
      />
    </div>
  )
}

export default HeroKSP
