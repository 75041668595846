import React, { useState } from 'react'
import { createHook } from 'react-sweet-state'
import { useStaticQuery, graphql } from 'gatsby'
import HeroKSP from './HeroKSP'

import CtaButton from '../components/CtaButton.js'

import Store from '../components/Store.js'
const useLanguage = createHook(Store)

const MainHero = () => {
  const [state, actions] = useLanguage()
  const data = useStaticQuery(graphql`
    query {
      allStrapiHeroSection {
        edges {
          node {
            title
            ctaText
            locale
            ctaButtonText
          }
        }
      }
    }
  `)

  const dataLocalized = data.allStrapiHeroSection.edges.filter(
    item => item.node.locale === state.language
  )[0]
  const { title, ctaText, ctaButtonText } = dataLocalized.node

  const renderHeroTitle = () => {
    const heroTitleWords = title.split(' ')

    let lineIndex = 0
    let lines = [[]]
    heroTitleWords.forEach((word, index) => {
      if (index % 3 === 0 && index !== 0) {
        lineIndex++
        lines[lineIndex] = []
      }
      lines[lineIndex].push(word)
    })

    return lines.map((words, wordsIndex) => (
      <>
        <span className='rounded-underline'>
          {words.map((word, wordIndex) =>
            wordIndex === words.length - 1 ? word : word + ' '
          )}
        </span>
        {wordsIndex === words.length - 1 ? null : <br />}
      </>
    ))
  }

  return (
    <div className='main-hero -wide'>
      <div className='wrapper'>
        <div className='illustration-wrapper'>
          <div className='hero-illustration -fun-nurse-photos'></div>
        </div>
        <div className='title-wrapper'>
          <div className='inner-wrapper'>
            <h1 className='hero-title'>{renderHeroTitle()}</h1>
            <div className='hero-cta'>
              <div className='cta-text'>
                <p>{ctaText}</p>
              </div>
              <CtaButton className='button -lg -pink' label={ctaButtonText} />
            </div>
          </div>
        </div>
        <div className='ksp-wrapper'>
          <HeroKSP transitionSpeed={0.2} animationSpeed={3} />
        </div>
      </div>
    </div>
  )
}

export default MainHero
