import React, { useState, useEffect } from 'react'
import { breakPoints } from './config'

const useBreakPoint = () => {
  const [breakPoint, setBreakPoint] = useState(null)

  const detectBreakPoint = () => {
    const breakPointArray = []
    const dimensions = []

    for (let breakPoint in breakPoints) {
      breakPointArray.push({
        name: breakPoint,
        dimension: breakPoints[breakPoint]
      })
      dimensions.push(breakPoints[breakPoint])
    }

    dimensions.sort((a, b) => a - b)

    let breakPointDimension = dimensions[0]
    dimensions.forEach(dimension => {
      if (window.innerWidth >= dimension) {
        breakPointDimension = dimension
      }
    })

    setBreakPoint(
      breakPointArray.filter(
        breakPoint => breakPoint.dimension === breakPointDimension
      )[0].name
    )
  }

  useEffect(() => {
    window.addEventListener('resize', detectBreakPoint)
    detectBreakPoint()
    return () => window.removeEventListener('resize', detectBreakPoint)
  }, [])

  return [breakPoint, setBreakPoint]
}

export default useBreakPoint
