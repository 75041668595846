import React, { useState, useEffect } from 'react'
import { rotate } from './functions'
import { useSwipeable } from 'react-swipeable'
import { breakPoints } from './config'

let slideTimout
import useBreakPoint from './useBreakPoint'

const useSlider = options => {
  const defaultOptions = {
    animationSpeed: 3,
    max: null,
    enableAtBreakPoint: null,
    slidesRef: null,
    slideClassName: 'slide',
    animationIntervallEnabled: true
  }

  const {
    animationIntervallEnabled,
    animationSpeed,
    max,
    enableAtBreakPoint,
    slidesRef,
    slideClassName
  } = { ...defaultOptions, ...options }

  const [slideIndex, setSlideIndex] = useState(0)
  const [slidesTransform, setSlidesTransform] = useState(0)
  const [breakPoint, setBreakPoint] = useBreakPoint()

  const enabled =
    !enableAtBreakPoint ||
    breakPoints[breakPoint] <= breakPoints[enableAtBreakPoint]

  const swipeHandlers = useSwipeable({
    onSwipedLeft: event => {
      if (enabled) {
        if (animationIntervallEnabled) {
          clearInterval(slideTimout)
        }
        updateIndex()
      }
    },
    onSwipedRight: event => {
      if (enabled) {
        if (animationIntervallEnabled) {
          clearInterval(slideTimout)
        }
        updateIndex(false)
      }
    }
  })

  const updateIndex = (incrementEnabled = true) => {
    setSlideIndex(slideIndex => rotate(slideIndex, max, incrementEnabled))
  }

  if (animationIntervallEnabled) {
    useEffect(() => {
      if (enabled) {
        slideTimout = setInterval(updateIndex, animationSpeed * 1000)
        return () => clearInterval(slideTimout)
      }
    }, [])
  }

  useEffect(() => {
    const { left: slidesLeft } = slidesRef.current.getBoundingClientRect()
    const { left: slideLeft } = slidesRef.current
      .querySelectorAll('.' + slideClassName)
      [slideIndex].getBoundingClientRect()

    setSlidesTransform(slidesLeft - slideLeft)
  }, [slideIndex])

  useEffect(() => {
    if (
      enableAtBreakPoint &&
      breakPoints[breakPoint] > breakPoints[enableAtBreakPoint]
    ) {
      setSlidesTransform(0)
    }
  }, [breakPoint])

  return {
    slideIndex,
    setSlideIndex,
    swipeHandlers,
    slideTimout,
    slidesTransform,
    updateIndex
  }
}

export default useSlider
